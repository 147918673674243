<template>
  <div>
    <Header
        v-model="headerLinks" v-bind:headerLinks="headerLinks"
    ></Header>

    <div class="container mt-2">

      <button class="btn btn-warning" style="border-radius: 12px;"><i class="fa fa-plus"></i> Добавить</button>

      <button class="btn btn-warning float-end" style="border-radius: 12px;"><i class="fa fa-user"></i> Перейти в ЛК Клиента</button>

      <div class="row">
        <div class="container mt-3">
        <select class="form-select form-select-sm" style="border-radius: 12px;" aria-label=".form-select-sm example">
          <option selected>Выбрать клиента</option>
          <option value="1">Клиент 1</option>
          <option value="2">Клиент 2</option>
          <option value="3">Клиент 3</option>
        </select>
        </div>
      </div>

      <div class="row">
        <div class="container mt-3">
          <div class="row">
            <div class="col-md-6">
              <div class="mb-3">
                <label for="exampleFormControlInput1" class="form-label">Количество входящих линий</label>
                <input type="number" class="form-control" style="border-radius: 12px;" id="exampleFormControlInput1" placeholder="Количество входящих линий">
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <label for="exampleFormControlInput1" class="form-label">Количество исходящих линий</label>
                <input type="number" class="form-control" style="border-radius: 12px;" id="exampleFormControlInput2" placeholder="Количество исходящих линий">
              </div>
            </div>
          </div>
        </div>
      </div>

<!--      <div class="row">-->
<!--        <Datatable :datatableId="'dt1'"/>-->
<!--      </div>-->

    </div>

  </div>
</template>

<script>
import Header from "@/components/Header";
// import Datatable from "@/components/Datatable";

export default {
  name: "ClientPreferences",
  components: {
    Header,
    // Datatable
  },
  data () {
    return {
      headerLinks: [
        {
          name: "Настройка ЛК Клиента",
          url: "/client_preferences"
        },
      ]
    }
  }
}
</script>

<style scoped>

</style>